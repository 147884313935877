import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from 'gatsby'

const Credits = ({ data, location }) => {
  const siteTitle = data?.site.siteMetadata?.title || `Title`
  return (
    <Layout location={location} title={siteTitle}>
      <a href='https://www.freepik.com/photos/heating-system'>Heating system photo created by senivpetro - www.freepik.com</a><br/>
      <a href='https://www.freepik.com/photos/fireplace'>Fireplace photo created by pvproductions - www.freepik.com</a>
    </Layout>
  )
}

export default Credits


/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
 export const Head = () => <Seo title="All posts" />

 export const pageQuery = graphql`
   query {
     site {
       siteMetadata {
         title
       }
     }
    }
 `
 